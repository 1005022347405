import React from "react";
import { FaHatCowboy,FaChalkboardTeacher } from "react-icons/fa";
import { VscBook } from "react-icons/vsc";
import { GiTeacher } from "react-icons/gi";
import { motion as m } from "framer-motion";
import { Helmet } from "react-helmet";
import Card from 'react-bootstrap/Card';
import { IoBookSharp } from "react-icons/io5";

const Computerscience = () => {
    return (
        <m.div
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 0.5 }}
        >
            <>
                <Helmet>
                    <title>Department of Computer Science |</title>
                </Helmet>
                {/* <Navcomponent /> */}
                <section id="courses" className="border-green">
                    <div className="course-mainheading">
                        <h1 className="font-tomatosemibold text-orange text-center"><IoBookSharp /> PROGRAM</h1>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row justify-center">
                            <div className="col-md-6">
                                <div className="course-div">
                                    <Card className="course-card">
                                        <div className="relative">
                                            <div className="courseimg-parent">
                                                <Card.Img variant="top" className="courseimg" src={'./assets/images/bscs.jpg'} alt="course" />
                                            </div>
                                            <div className="course-pill">
                                                <FaHatCowboy />  Computer Science
                                            </div>
                                        </div>
                                        <Card.Body className="course-cardbody">
                                            <Card.Title className="course-cardtitle">BS Computer Science</Card.Title>
                                            <Card.Text className="caourse-detailpara">
                                            The BSCS program at the Department of Computer Sciences equips students with a robust foundation in core computer science concepts and emerging technologies. The curriculum covers areas such as Artificial Intelligence, Cyber Security, Web Engineering, Mobile Application Development, Advanced Programming, and Cloud Computing. The department fosters a collaborative learning environment with state-of-the-art facilities to help students innovate and apply theoretical knowledge to practical challenges, preparing them for careers in various sectors including tech and research.
                                            </Card.Text>
                                            <div className="book float-right">
                                                <VscBook />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>        
                        </div>
                    </div>
                </section>

                {/* <Footer /> */}
            </>
        </m.div>
    )


}

export default Computerscience