import React from "react";
import { Helmet } from "react-helmet";
import { motion as m } from "framer-motion";
import bsdicipline from '../Documents/BS-Disciplines.pdf'

const AdmissionFall2024 = () => {
    return (
        <m.div
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Helmet>
                <title>Admission Fall 2024 |</title>
            </Helmet>
            <section id="AdmissionFall2024">
                <div className="container">
                    <div className="row mtb">
                        <div className="admission-box">
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/bsadmission.jpeg"} alt="admission" />
                            <div>
                                <p className="font-tomato">Admission Schedule for Affiliated Colleges. BS Admissions Open wef June 24, 2024. <a href={bsdicipline} target="_blank" rel="noreferrer">Click here</a></p>
                            </div>
                        </div>
                        <div className="admission-box">
                           
                            <div>
                                <p className="font-tomato">Admission Schedule for Affiliated Colleges. BS Admissions Open wef June 24, 2024. <a href={bsdicipline} target="_blank" rel="noreferrer">Click here</a></p>
                            </div>
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/admission2.jpg"} alt="admission" />
                        </div>
                        <div className="admission-box">
                        <img
                               src={process.env.PUBLIC_URL + "/assets/images/admission3.jpg"} alt="admission" />
                           <div>
                               <p className="font-tomato">Admission Schedule for Affiliated Colleges. BS Admissions Open wef June 24, 2024. <a href={bsdicipline} target="_blank" rel="noreferrer">Click here</a></p>
                           </div>
                           
                       </div>
                    </div>
                </div>

            </section>
        </m.div>
    );
}

export default AdmissionFall2024;