import React from "react";
import { FaChalkboardTeacher } from "react-icons/fa";
import { motion as m } from "framer-motion";
import { Helmet } from "react-helmet";

const FacultyMathematics = () => {
    return (
        <m.div
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 0.5 }}
        >
            <>
                <Helmet>
                    <title>Faculty of Mathematics |</title>
                </Helmet>
                {/* <Navcomponent /> */}
                <section id="courses" className="border-green">
                    <div className="course-mainheading">
                        <h1 className="font-tomatosemibold text-orange text-center"><FaChalkboardTeacher />  FACULTY</h1>
                    </div>
                </section>
                {/* <Footer /> */}
            </>
        </m.div>
    )


}

export default FacultyMathematics