import React from "react";
import { motion as m } from "framer-motion";
import { Helmet } from "react-helmet";

const Registrar = () => {
    return (
        <m.div
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 0.5 }}
        >
           
                <Helmet>
                    <title>Registrar |</title>
                </Helmet>
                <section id="administration">
                    <div className="container">
                        <div className="row">
                            <div className="admin-heading flex-center">
                                     <h1 className="admin-text">REGISTRAR</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="main-administration" className="lightgeenbg">
                        <div className="row">
                            <div className="col-md-6 middle border-rightgreen">
                                <div className="admin-img">
                                    <img className="img-admin" src={'./assets/images/unknown.png'} alt="Card image cap" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="about-admin">
                                    <div className="pill font-tomato">
                                        Registrar's Profile
                                    </div>
                                    <div className="about-adminpara">
                                        <p className="font-tomato">The profile of a university Registrar encompasses a blend of academic distinction, leadership prowess, and a deep commitment to educational advancement. Typically holding a doctoral degree in their field of expertise, the Registrar often boasts a distinguished career as a researcher, educator, or administrator prior to assuming the role. Their academic credentials, coupled with extensive experience in higher education, equip them to steer the university towards academic excellence and innovation.
                                            <br /><br />
                                            Beyond academic qualifications, a successful Registrar possesses strong leadership skills, including strategic planning, decision-making, and team-building abilities. They demonstrate a keen understanding of institutional governance and have the acumen to navigate complex challenges facing modern universities, such as funding constraints, technological advancements, and evolving student needs. Effective communication skills are paramount, enabling the Registrar to engage with diverse stakeholders—from faculty and students to alumni, donors, and community partners—to foster collaboration and support for the university's mission and vision.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>
        </m.div>
    )
}

export default Registrar