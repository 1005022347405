import React from "react";
import { motion as m } from "framer-motion";
import { Helmet } from "react-helmet";
import { IoBookSharp } from "react-icons/io5";
import { FaHatCowboy } from "react-icons/fa";
import { VscBook } from "react-icons/vsc";
import Card from 'react-bootstrap/Card';

const SpecialEducation = () => {
    return (
        <m.div
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 0.5 }}
        >
            <>
                <Helmet>
                    <title>Department of Special Education |</title>
                </Helmet>
                {/* <Navcomponent /> */}
                <section id="courses" className="border-green">
                    <div className="course-mainheading">
                        <h1 className="font-tomatosemibold text-orange text-center"><IoBookSharp /> PROGRAM</h1>
                    </div>
                </section>
            
                <section>
                    <div className="container">
                        <div className="row justify-center">
                            <div className="col-md-6">
                                <div className="course-div">
                                    <Card className="course-card">
                                        <div className="relative">
                                            <div className="courseimg-parent">
                                                <Card.Img variant="top" className="courseimg" src={'./assets/images/bseconomics.jpg'} alt="course" />
                                            </div>
                                            <div className="course-pill">
                                                <FaHatCowboy />  Special Education
                                            </div>
                                        </div>
                                        <Card.Body className="course-cardbody">
                                            <Card.Title className="course-cardtitle">BS Special Education</Card.Title>
                                            <Card.Text className="caourse-detailpara">
                                            The University offers a BS in Special Education, dedicated to teaching students with diverse learning needs. Students study inclusive education strategies, developmental psychology, and classroom management. The program prepares graduates for careers in special education, counseling, and advocacy. Field experiences and internships provide practical training and real-world application.
                                            </Card.Text>
                                            <div className="book float-right">
                                                <VscBook />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                      
                        </div>
                    </div>
                </section>
                {/* <Footer /> */}
            </>
        </m.div>
    )


}

export default SpecialEducation