import React from "react";
import { FaHatCowboy, FaChalkboardTeacher } from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import { motion as m } from "framer-motion";
import { Helmet } from "react-helmet";
import Card from 'react-bootstrap/Card';


const FacultySocialScience = () => {
    return (
        <m.div
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 0.5 }}
        >
            <>
                <Helmet>
                    <title>Faculty of Social Sciences, Humanities & Arts |</title>
                </Helmet>
                <section id="courses" className="border-green">
                    <div className="course-mainheading">
                        <h1 className="font-tomatosemibold text-orange text-center"><FaChalkboardTeacher />  FACULTY</h1>
                    </div>
                </section>
                <section id="faculty">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="course-div">
                                    <Card className="course-card">
                                        <div className="relative">
                                            <div className="faculty-parent">
                                                <Card.Img variant="top" className="courseimg" src={'./assets/images/Asif-removebg-preview.jpg'} alt="course" />
                                            </div>
                                            <div className="course-pill">
                                                <FaHatCowboy />  Associate Professor
                                            </div>
                                        </div>
                                        <Card.Body className="course-cardbody">
                                            <Card.Title className="course-cardtitle">Dr. Muhammad Asif Gul</Card.Title>
                                            <Card.Text className="caourse-detailpara">
                                                <h3>Qualification </h3>
                                                <p><b>PhD in Linguistics</b><br />
                                                    Foundation University, Islamabad</p>
                                                <h4>Areas of Interest:</h4>
                                                Linguistics<br />
                                                Curriculum Development & Syllabus Design<br />
                                                Language Planning & Policy<br />
                                                ELT and Technology<br />
                                                Corpus Linguistics
                                            </Card.Text>
                                            <div className="book float-right">
                                                <GiTeacher />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="course-div margin0">
                                    <Card className="course-card">
                                        <div className="relative">
                                            <div className="faculty-parent">
                                                <Card.Img variant="top" className="courseimg" src={'./assets/images/wajiha.jpg'} alt="course" />
                                            </div>
                                            <div className="course-pill">
                                                <FaHatCowboy /> Associate Professor
                                            </div>
                                        </div>
                                        <Card.Body className="course-cardbody">
                                            <Card.Title className="course-cardtitle">Dr. Wajiha Yasir</Card.Title>
                                            <Card.Text className="caourse-detailpara">
                                            <h3>Qualification </h3>
                                                <p><b>PhD</b><br/>
                                                Hazara university Mansehra</p>
                                            <h4>Areas of Interest:</h4>
                                                Counseling<br/>NLP<br/>Psychotherapy
                                            </Card.Text>
                                            <div className="book float-right">
                                                <GiTeacher />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="course-div">
                                    <Card className="course-card">
                                        <div className="relative">
                                            <div className="faculty-parent">
                                                <Card.Img variant="top" className="courseimg" src={'./assets/images/aniqa.jpg'} alt="course" />
                                            </div>
                                            <div className="course-pill">
                                                <FaHatCowboy />  Assistant Professor
                                            </div>
                                        </div>
                                        <Card.Body className="course-cardbody">
                                            <Card.Title className="course-cardtitle">Ms. Aniqa Iqbal</Card.Title>
                                            <Card.Text className="caourse-detailpara">
                                                <h4>Areas of Interest:</h4>
                                                Literary theory<br />
                                                Psychoanalysis<br />
                                                Postcolonialism<br />
                                                Ecocriticism
                                            </Card.Text>
                                            <div className="book float-right">
                                                <GiTeacher />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="course-div margin0">
                                    <Card className="course-card">
                                        <div className="relative">
                                            <div className="faculty-parent">
                                                <Card.Img variant="top" className="courseimg" src={'./assets/images/aisha.jpg'} alt="course" />
                                            </div>
                                            <div className="course-pill">
                                                <FaHatCowboy />  Assistant Professor
                                            </div>
                                        </div>
                                        <Card.Body className="course-cardbody">
                                            <Card.Title className="course-cardtitle">Dr. Aisha Perveen</Card.Title>
                                            <Card.Text className="caourse-detailpara">
                                            <h3>Qualification </h3>
                                                <p><b>PhD Psychology</b><br/>
                                               </p>
                                            <h4>Areas of Interest:</h4>
                                               Positive Psychology<br/>
                                               Clinical and Counseling Psychology<br/>
                                               Muslim Psychology<br/>
                                               Family Sciences
                                            </Card.Text>
                                            <div className="book float-right">
                                                <GiTeacher />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="course-div margin0">
                                    <Card className="course-card">
                                        <div className="relative">
                                            <div className="faculty-parent">
                                                <Card.Img variant="top" className="courseimg" src={'./assets/images/tuba.jpg'} alt="course" />
                                            </div>
                                            <div className="course-pill">
                                                <FaHatCowboy />  Lecturer
                                            </div>
                                        </div>
                                        <Card.Body className="course-cardbody">
                                            <Card.Title className="course-cardtitle">Tuba Ghaffar</Card.Title>
                                            <Card.Text className="caourse-detailpara">
                                            <h3>Qualification </h3>
                                                <p><b>MS Clinical Psychology</b><br/>
                                                International Islamic University Islamabad</p>
                                            <h4>Areas of Interest:</h4>
                                               Assessment & Diagnosis<br/>
                                               Therapuetic Intervention Counselling<br/>
                                               Cognitive processing<br/>
                                               Behavior modification
                                            </Card.Text>
                                            <div className="book float-right">
                                                <GiTeacher />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <Footer /> */}
            </>
        </m.div>
    )


}

export default FacultySocialScience