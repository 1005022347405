import './index.css'
import './App.css'
import './mediaQueries.css'
import React, { useEffect, lazy, Suspense } from 'react'
import { useLocation, Routes, Route } from 'react-router-dom'
import MouseFollower from 'mouse-follower'
import Footer from './components/footer'
import Navcomponent from './components/navbar'
import Allblogs from './components/allblogs'
import { Helmet } from 'react-helmet'
import Charter from './components/charter'
import BSdiscipline from './components/bs-discipline'
import Applicationform from './components/application'
import Schedule from './components/schedule'
import Academicsystem from './components/academic'
import ACDcalender from './components/academic-calender'
import SchemesofStudies from './components/schemes'
import Mainblogs from './components/mainBlogs'
import Blogsdetail from './components/blodDetails'
import Careers from './components/careers'
import Rector from './components/rector'
import Registrar from './components/registrar'
import SpecialEducation from './programs/specialeducation'
import Emergingtechnologies from './programs/emegingtechnologies'
import AdmissionFall2024 from './components/admissionfall2024'
import InstructiononAffiliation from './components/instruction-affiliation'
import FacultyComputerscience from './faculty/faculty-cs'
import FacultyMathematics from './faculty/faculty-math'
import FacultyPhysics from './faculty/faculty-physics'
import FacultyManagementSciences from './faculty/faculty-managementscience'
import FacultyPsychology from './faculty/faculty-psychology'
import FacultyUrdu from './faculty/faculty-urdu'
import FacultyEnglish from './faculty/faculty-english'
import FacultySpecialEducation from './faculty/faculty-specialeducation'
import FacultyEmergingtechnologies from './faculty/faculty-emergingtechnologies'
import FacultyInformationTechnology from './faculty/faculty-informationtechnology'
import FacultyFineArts from './faculty/faculty-finearts'
import FacultyBotany from './faculty/faculty-botany'
import FacultyHealthandPhysicalEducation from './faculty/faculty-healthphysical'
import FacultyIslamicStudies from './faculty/faculty-islamicstudies'
import FacultyPoliticalScience from './faculty/faculty-politicalscience'
import Computerscience from './programs/computerscience'
import Mathematics from './programs/mathematics'
import ManagementSciences from './programs/managementscience'
import Psychology from './programs/psychology'
import Urdu from './programs/urdu'
import Economics from './programs/economics'
import Physics from './programs/physics'
import English from './programs/english'
import Informationtechnology from './programs/informationtechnology'
import Botany from './programs/botany'
import FineArts from './programs/finearts'
import HealthPhysicalEducation from './programs/health-physicaleducation'
import IslamicStudies from './programs/islamicstudies'
import PoliticalScience from './programs/politicalscience'
import Gallery from './components/gallery'
import Faqs from './components/faqs'
import gsap from 'gsap'
import AOS from "aos";
import "aos/dist/aos.css";
import FacultySocialScience from './faculty/faculty-socialscience-humanities-arts'
// gsap.registerPlugin(ScrollTrigger);
MouseFollower.registerGSAP(gsap)

const Home = lazy(() => import('./components/Home'))
const Aboutus = lazy(() => import('./components/aboutus'))
const AffiliatedColleges = lazy(() => import('./components/affiliated-colleges'))
const Email = lazy(() => import('./components/email'))

function App() {
  // const [loading, setLoading] = useState(true)
  const location = useLocation()
  // const spinner = document.getElementById('pre-loader')

  const cursor = new MouseFollower()
  useEffect(() => {
    // ScrollSmoother.create({
    //   wrapper: '#smooth-wrapper',
    //   content: '#smooth-content',
    //   smooth: 2,
    //   smoothTouch: 0.1,
    //   effects: true
    // })
    var backtoTop = document.getElementById("back-to-top");
    window.addEventListener("scroll", function () {
      if (window.scrollY > 20) {
        backtoTop.classList.add("active");
      } else {
        backtoTop.classList.remove("active");
      }
      backtoTop.style.transform = "rotate(" + window.pageYOffset + "deg)";
    });
    const elements1 = document.querySelectorAll('.card-img-top')
    elements1.forEach((element) => {
      element.addEventListener('mouseenter', () => {
        cursor.setText('')
      })
      element.addEventListener('mouseleave', () => {
        cursor.removeText()
      })
    })

    const elements2 = document.querySelectorAll('.blogcovers')
    elements2.forEach((element) => {
      element.addEventListener('mouseenter', () => {
        cursor.setText('EXPLORE')
      })
      element.addEventListener('mouseleave', () => {
        cursor.removeText()
      })
    })

    AOS.init();
  })

  // if (spinner) {
  // jQuery(document).ready(function () {
  //   setTimeout(() => {
  //     jQuery(spinner).slideUp(1200)
  //   }, 1000)
  //   // setLoading(false)
  // })
  // setTimeout(() => {
  // spinner.style.display = 'none'
  // setLoading(false)
  // }, 3000)
  // }

  // var cursor = document.querySelector('.cursor');

  return (
    <div className="App">
      <a id="back-to-top" href="#" className="back-to-top" role="button">
        <p className="scrollbtnp">Up</p>
      </a>
      {/* <AnimatePresence mode="wait" initial={false} exitBeforeEnter> */}
        <Helmet>
          <title>National University of Pakistan</title>
          <meta name="description" content="Get info about NUP" />
          <meta
            name="keywords"
            content=""
          />
        </Helmet>
          <Navcomponent />
          <Suspense fallback={<h1>Loading...</h1>}>
            <Routes location={location} key={location.pathname}>
              <Route exact path="/" element={<Home />} />
              <Route path="/about-us" element={<Aboutus />} />
              <Route path="/contact" element={<Email />} />
              <Route path="/affiliated-colleges" element={<AffiliatedColleges />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path='/careers' element={<Careers />} />
              <Route path='/faqs' element={<Faqs />} />
              <Route path="/charter-of-nup" element={<Charter />} />
              <Route path="/instruction-on-affiliation" element={<InstructiononAffiliation />} />
              <Route path="/admission-fall-2024" element={<AdmissionFall2024 />} />
              <Route path="/bs-discipline" element={<BSdiscipline />} />
              <Route path="/college-affiliation-form" element={<Applicationform />} />
              <Route path="/admission-schedule" element={<Schedule />} />
              <Route path="/academic-system" element={<Academicsystem />} />
              <Route path="/schemes-of-studies" element={<SchemesofStudies />} />
              <Route path="/academic-calender" element={<ACDcalender />} />
              <Route path="/rector" element={<Rector />} />
              <Route path="/registrar" element={<Registrar />} />
              <Route path="/department-of-computer-science" element={<Computerscience />} />
              <Route path="/faculty-of-computer-science" element={<FacultyComputerscience />} />
              <Route path="/department-of-information-technology" element={<Informationtechnology />} />
              <Route path="/faculty-of-information-technology" element={<FacultyInformationTechnology />} />
              <Route path="/department-of-mathematics" element={<Mathematics />} />
              <Route path="/faculty-of-mathematics" element={<FacultyMathematics />} />
              <Route path="/department-of-physics" element={<Physics />} />
              <Route path="/faculty-of-physics" element={<FacultyPhysics />} />
              <Route path="/department-of-botany" element={<Botany />} />
              <Route path="/faculty-of-botany" element={<FacultyBotany />} />
              <Route path="/department-of-management-sciences" element={<ManagementSciences />} />
              <Route path="/faculty-of-management-sciences" element={<FacultyManagementSciences />} />
              <Route path="/department-of-psychology" element={<Psychology />} />
              <Route path="/faculty-of-psychology" element={<FacultyPsychology />} />
              <Route path="/department-of-urdu" element={<Urdu />} />
              <Route path="/faculty-of-urdu" element={<FacultyUrdu />} />
              <Route path="/department-of-economics" element={<Economics />} />
              <Route path="/faculty-of-economics" element={<FacultyUrdu />} />
              <Route path="/department-of-english" element={<English />} />
              <Route path="/faculty-of-english" element={<FacultyEnglish />} />
              <Route path="/department-of-fine-arts" element={<FineArts />} />
              <Route path="/faculty-of-fine-arts" element={<FacultyFineArts />} />
              <Route path="/department-of-physical-education" element={<HealthPhysicalEducation />} />
              <Route path="/faculty-of-physical-education" element={<FacultyHealthandPhysicalEducation />} />
              <Route path="/department-of-islamic-studies" element={<IslamicStudies />} />
              <Route path="/faculty-of-islamic-studies" element={<FacultyIslamicStudies />} />
              <Route path="/department-of-political-science" element={<PoliticalScience />} />
              <Route path="/faculty-of-political-science" element={<FacultyPoliticalScience />} />
              <Route path="/department-of-special-education" element={<SpecialEducation />} />
              <Route path="/faculty-of-special-education" element={<FacultySpecialEducation />} />
              <Route path="/department-of-emerging-technologies" element={<Emergingtechnologies />} />
              <Route path="/faculty-of-emerging-technologies" element={<FacultyEmergingtechnologies />} />
              <Route path="/faculty-of-socialscience-humanities&arts" element={<FacultySocialScience />} />
              <Route path="/work" element={<Allblogs />} />
              <Route path="/blogs" element={<Mainblogs />} />
              <Route path="/allblogs/:id" element={<Blogsdetail />} />
              {/* <Route path="/terms&conditions" element={<Termsandconditions />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} /> */}

            </Routes>
          </Suspense>
          <Footer />
      {/* </AnimatePresence> */}
    </div>
  )
}
export default App
