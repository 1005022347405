import React from "react";
import Button from 'react-bootstrap/Button';
import academicsystem from '../Documents/Academic-System-Affiliated-Colleges-II-11.pdf'
import { HashLink as Link } from "react-router-hash-link";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { motion as m } from "framer-motion";
import "react-toastify/dist/ReactToastify.css";

const Academicsystem = () => {
    return (
        <m.div
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Helmet>
                <title>Academic System |</title>
            </Helmet>
            <section id="academicbg" className="bgimagesetting">
                <h1 className="">ACADEMIC SYSTEM</h1>
                <div id="overlay"></div>
            </section>
            <div className="container">
                <div className="row mtb">
                    <a href={academicsystem} target="_blank" rel="noreferrer">
                        <Button className="Contact-btn read-btn">Click here</Button>{' '}
                    </a>
                </div>
            </div>
        </m.div>
    );
};
export default Academicsystem