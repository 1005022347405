import React from "react";
import bsdicipline from '../Documents/BS-Disciplines.pdf'
import Accordion from 'react-bootstrap/Accordion';
import { Helmet } from "react-helmet";
import { SiAnswer } from "react-icons/si";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Navcomponent from "./navbar";
import Footer from "./footer";

const Faqs = () => {
    return (
        <>
            <Helmet>
                <title>FAQ'S |</title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
            </Helmet>
            <Navcomponent/>
            <section id="news">
                <div className="container">
                    <div className="row justify-center">
                        <div className="faq-heading">
                            <h1 className='exteriortext' data-aos="fade-down"><SiAnswer /> Frequently Asked Questions (FAQs)</h1>
                        </div>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>How do I apply for admission?</Accordion.Header>
                                <Accordion.Body>
                                    Please visit your nearby F.G. Degree College (Cantt/Garrison) and collect your application form.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What documents do I need to submit with my application?</Accordion.Header>
                                <Accordion.Body>
                                    Required documents:
                                    <ul>
                                        <li>A copy of Matric Marksheet/Equivalence Certificate of O-Level</li>
                                        <li>A copy of Part-1/Intermediate Marksheet/Equivalence Certificate of A-Level</li>
                                        <li>A copy of CNIC</li>
                                        <li>A copy of Father/Mother's CNIC</li>
                                        <li>4 Passport size Photos with blue background</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>What undergraduate programs do you offer?</Accordion.Header>
                                <Accordion.Body>
                                    We offer a wide range of undergraduate programs across various disciplines, including arts and humanities, sciences, engineering, business, and social sciences. Visit our academic programs page for a complete list <a href={bsdicipline} target="_blank" rel="noreferrer">Click here</a>.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default Faqs;