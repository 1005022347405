import React from "react";
import { FaHatCowboy,FaChalkboardTeacher } from "react-icons/fa";
import { VscBook } from "react-icons/vsc";
import { motion as m } from "framer-motion";
import { Helmet } from "react-helmet";
import Card from 'react-bootstrap/Card';
import { IoBookSharp } from "react-icons/io5";

const IslamicStudies = () => {
    return (
        <m.div
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 0.5 }}
        >
            <>
                <Helmet>
                    <title>Department of Islamic Studies |</title>
                </Helmet>
                {/* <Navcomponent /> */}
                <section id="courses" className="border-green">
                    <div className="course-mainheading">
                        <h1 className="font-tomatosemibold text-orange text-center"><IoBookSharp /> PROGRAM</h1>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row justify-center">
                            <div className="col-md-6">
                                <div className="course-div">
                                    <Card className="course-card">
                                        <div className="relative">
                                            <div className="courseimg-parent">
                                                <Card.Img variant="top" className="courseimg" src={'./assets/images/islamic.png'} alt="course" />
                                            </div>
                                            <div className="course-pill">
                                                <FaHatCowboy />  Islamic Studies
                                            </div>
                                        </div>
                                        <Card.Body className="course-cardbody">
                                            <Card.Title className="course-cardtitle">BS Islamic Studies</Card.Title>
                                            <Card.Text className="caourse-detailpara">
                                            The University offers a BS in Islamic Studies, exploring Islamic history, culture, and theology. Students delve into Quranic studies, jurisprudence, and contemporary issues in the Muslim world. The program prepares graduates for careers in education, research, and community leadership. Engagement with primary texts and research projects enriches academic understanding.
                                            </Card.Text>
                                            <div className="book float-right">
                                                <VscBook />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>

                {/* <Footer /> */}
            </>
        </m.div>
    )


}

export default IslamicStudies