import React from "react";
import { FaChalkboardTeacher } from "react-icons/fa";
import { motion as m } from "framer-motion";
import { Helmet } from "react-helmet";

const FacultyFineArts = () => {
    return (
        <m.div
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 0.5 }}
        >
            <>
                <Helmet>
                    <title>Faculty of Fine Arts |</title>
                </Helmet>
                {/* <Navcomponent /> */}
                <section id="courses" className="border-green">
                    <div className="course-mainheading">
                        <h1 className="font-tomatosemibold text-orange text-center"><FaChalkboardTeacher />  FACULTY</h1>
                    </div>
                </section>
                {/* <section id="faculty">
                    <div className="container">
                        <div className="row justify-center">
                            <div className="col-md-6">
                                <div className="course-div">
                                    <Card className="course-card">
                                        <div className="relative">
                                            <div className="courseimg-parent">
                                                <Card.Img variant="top" className="courseimg" src={'./assets/images/drfaheem.jpeg'} alt="course" />
                                            </div>
                                            <div className="course-pill">
                                                <FaHatCowboy />  Dean
                                            </div>
                                        </div>
                                        <Card.Body className="course-cardbody">
                                            <Card.Title className="course-cardtitle">Dr. Muhammad Fahim Arif</Card.Title>
                                            <Card.Text className="caourse-detailpara">
                                                Professor<br/>
                                                Areas of Interest:<br/>
                                                Software quality engineering & Machine Learning
                                            </Card.Text>
                                            <div className="book float-right">
                                                <GiTeacher />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <Footer /> */}
            </>
        </m.div>
    )


}

export default FacultyFineArts