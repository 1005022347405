import React from 'react'
import { motion as m } from 'framer-motion'
import { Helmet } from 'react-helmet'

const Careers = () => {
 
  return (
    <m.div initial={{ scaleY: 0 }} animate={{ scaleY: 1 }} exit={{ scaleY: 0 }} transition={{ duration: 0.5 }}>
      <>
        <Helmet>
          <title>Careers |</title>
          {/* <meta name="description" content="" />
          <meta
            name="keywords"
            content="digital marketing agency, digital marketing agency pakistan, digital advertising agency, digital advertising agencies near me, branding agencies, branding and marketing agency, digital strategy, web development, app development, media production, event management, rebranding, brand management, social media marketing, corporate branding, corporate marketing strategy, print media, digital media        "
          /> */}
        </Helmet>
         <section>
           <div className='container'>
            <div className='row text-center mt-40 mb-40'>
              <h1 className='superscript'>Jobs</h1>
              <p>Thank you for your interest, but there are no job openings available at this time</p>
            </div>
           </div>
         </section>
      </>
    </m.div>
  )
}

export default Careers
