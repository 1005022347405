import React from "react";
import { FaHatCowboy, FaChalkboardTeacher } from "react-icons/fa";
import { VscBook } from "react-icons/vsc";
import { GiTeacher } from "react-icons/gi";
import { motion as m } from "framer-motion";
import { Helmet } from "react-helmet";
import Card from 'react-bootstrap/Card';
import { IoBookSharp } from "react-icons/io5";

const Psychology = () => {
    return (
        <m.div
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 0.5 }}
        >
            <>
                <Helmet>
                    <title>Department of Psychology |</title>
                </Helmet>
                {/* <Navcomponent /> */}
                <section id="courses" className="border-green">
                    <div className="course-mainheading">
                        <h1 className="font-tomatosemibold text-orange text-center"><IoBookSharp /> PROGRAM</h1>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row justify-center">
                            <div className="col-md-6">
                                <div className="course-div">
                                    <Card className="course-card">
                                        <div className="relative">
                                            <div className="courseimg-parent">
                                                <Card.Img variant="top" className="courseimg" src={'./assets/images/bspsychology.jpg'} alt="course" />
                                            </div>
                                            <div className="course-pill">
                                                <FaHatCowboy />  Psychology
                                            </div>
                                        </div>
                                        <Card.Body className="course-cardbody">
                                            <Card.Title className="course-cardtitle">BS Psychology</Card.Title>
                                            <Card.Text className="caourse-detailpara">
                                            The BS Psychology program at the Department of Applied Psychology offers students cutting-edge research opportunities in all areas of psychology particularly in learning, memory, decision-making, cognitive neuroscience and psychological disorders etc. The program is designed to prepare students for impactful careers in psychology and community settings, fostering critical thinking, cultural awareness, and ethical responsibility. The department is committed to academic excellence and interdisciplinary collaboration, providing students with the skills necessary to contribute meaningfully to societal well-being.
                                            </Card.Text>
                                            <div className="book float-right">
                                                <VscBook />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                    
                        </div>
                    </div>
                </section>
                
                {/* <Footer /> */}
            </>
        </m.div>
    )


}

export default Psychology