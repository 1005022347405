import React from "react";
import Button from 'react-bootstrap/Button';
import admisionschedule from '../Documents/13-Sep-Revised-Admissions-Schedule.pdf'
import { HashLink as Link } from "react-router-hash-link";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { motion as m } from "framer-motion";
import "react-toastify/dist/ReactToastify.css";

const Schedule = () => {
    return (
        <m.div
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Helmet>
                <title>Admission Schedule Fall-2024 |</title>
            </Helmet>
            <section id="schedulebg" className="bgimagesetting">
                <h1 className="">ADMISSION SCHEDULE FALL-2024</h1>
                <div id="overlay"></div>
            </section>
            <div className="container">
                <div className="row mtb">
                    <a href={admisionschedule} target="_blank" rel="noreferrer">
                        <Button className="Contact-btn read-btn">Click here</Button>{' '}
                    </a>
                </div>
            </div>
        </m.div>
    );
};
export default Schedule