import React from "react";
import { motion as m } from "framer-motion";
import Card from 'react-bootstrap/Card';
import { MdArrowRightAlt } from "react-icons/md";
import { FaRegNewspaper } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Mainblogs = () => {

  // useEffect(() => {
  //     let revealtext = document.querySelectorAll(".blogs-heading");
  //     const textheritagereveal = new SplitType('.blogs-heading')
  //     let tl1 = gsap.timeline({
  //         scrollTrigger: {
  //             trigger: '#mainblogs',
  //             toggleActions: "restart none none reset"
  //         }
  //     });

  //     tl1.to(textheritagereveal.chars,{
  //         y: 0,
  //         stagger: 0.05,
  //         delay: 0.2,
  //         duration: 0.1
  //     })
  // },[])
  return (
    <m.div
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
      transition={{ duration: 0.5 }}
    >
      <>
        {/* <Helmet>
          <title>Blogs |</title>
          <meta name="description" content="Get info about ConstantinePR" />
          <meta
            name="keywords"
            content="digital marketing agency, digital marketing agency pakistan, digital advertising agency, digital advertising agencies near me, branding agencies, branding and marketing agency, digital strategy, web development, app development, media production, event management, rebranding, brand management, social media marketing, corporate branding, corporate marketing strategy, print media, digital media        "
          />
        </Helmet> */}
        <section id="mainblogs">
          <div className="container">
            <div className="row justify-center">
              <div className="faq-heading">
                <h1 className='exteriortext' data-aos="fade-down"><FaRegNewspaper /> Latest News</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5">
                <a href={'/allblogs/' + 1} >
                  <Card>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + "/assets/images/bsadmission.jpeg"} />
                    <Card.Body>
                      <Card.Title>Announcement of Admission Schedule for Affiliated Colleges. BS Admissions Open wef</Card.Title>
                      <Card.Text>
                      <MdArrowRightAlt /> <span className="date">06 June 2024 </span>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </div>
              <div className="offset-md-2 col-md-5">
                <a href={'/allblogs/' + 2} >
                  <Card>
                    <Card.Img variant="top" src={process.env.PUBLIC_URL + "/assets/images/academicsystemaffiliation.png"} />
                    <Card.Body>
                      <Card.Title>Academic System for Affiliated Colleges
                        </Card.Title>
                      <Card.Text>
                      <MdArrowRightAlt /> <span className="date">7 June 2024 </span>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </div>
            </div>
          </div>
        </section>
      </>
    </m.div>
  );
};

export default Mainblogs;
