import React from "react";
import { FaPhoneAlt, FaAddressCard } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
  return (
    <>
      <footer id="footer">
        {/* <section className="footer-container"> */}
        <div className="container footercontent">
          <div className="row footerallcontent">
            <div className="col-md-2">
              <div className="gridview">
                <h4>Pages</h4>
                <div className="footerlinks mar15">
                  <a className="nav-link" href="/">
                    Home
                  </a>
                  <a className="nav-link" href="/about-us">
                    About Us
                  </a>
                  <a className="nav-link" href="/contact">
                    Contact
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="gridview">
                <h4>Links</h4>
                <div className="footerlinks mar15">
                  <a
                    href="/faculty-of-computer-science"
                    className="nav-link"
                  >
                    Faculty of Computing
                  </a>
                  <a
                    href="/faculty-of-mathematics"
                    className="nav-link"
                  >
                    Faculty of Natural & Applied Sciences
                  </a>
                  <a
                    href="/faculty-of-management-sciences"
                    className="nav-link"
                  >
                    Faculty of Management Sciences
                  </a>
                  <a
                    href="/faculty-of-socialscience-humanities&arts"
                    className="nav-link"
                  >
                    Faculty of Social Sciences, Humanities & Arts
                  </a>
                  <a
                    href="/faculty-of-emerging-technologies"
                    className="nav-link"
                  >
                    Faculty of Engineering & Emerging Technologies
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <h4>Contact Us</h4>
              <div className="footerlinks mar15">
                <div className="footericondiv footer-mobileicondiv">
                  <div className="footer-icon footer-socialicon">
                    <FaPhoneAlt />
                  </div>
                  <div>
                  <a href="tel:+92-51-5910020"><h5 className="font-tomato text-white">+92-51-5910020</h5></a>
                    {/* <h5 className="font-tomato text-white">+92-51-5910020</h5> */}
                  </div>
                </div>
                <div className="footericondiv footer-mobileicondiv">
                  <div className="footer-icon footer-socialicon">
                    <MdEmail />
                  </div>
                  <div>
                  <a href="mailto:info@edu.nup.pk" className="font-tomato text-white"><h5 className="font-tomato text-white">info@edu.nup.pk</h5></a>
                    {/* <h5 className="font-tomato text-white">info@edu.nup.pk</h5> */}
                  </div>
                </div>
                <div className="footericondiv">
                  <div className="footer-icon footer-socialicon">
                    <FaAddressCard />
                  </div>
                  <div>
                    <h5 className="font-tomato text-white">Sir Syed Road, Rawalpindi Cantt</h5>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-3">
              <h4>Social Media</h4>
              <div className="footerlinks mar15">
                <div className="footericondiv">
                  <div className="footer-icon footer-socialicon">
                    <a href="https://web.facebook.com/nupakofficial/" className="fb-iconparent"><FaFacebookF className="fb-icon" /></a>
                  </div>
                  <div>
                    <h5 className="font-tomato text-white">Facebook</h5>
                  </div>
         x        </div>  
              </div>
            </div> */}
             <div className="col-md-2">
              <div className="gridview">
                <h4>Legal</h4>
                <div className="footerlinks mar15">
                  <a className="nav-link" href="faqs">
                    FAQ's
                  </a>
                  {/* <a className="nav-link" href="/about-us">
                    About Us
                  </a>
                  <a className="nav-link" href="/contact">
                    Contact
                  </a> */}
                </div>
              </div>
            </div>
            {/* <div className="col-md-4">
                <div className="gridview gridview1">
                  <h4>Newsletter</h4>
                  <div className="footerlinks mar20">
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="email" placeholder="Enter email" />
                        <Button type="submit" className="sbmitbtn">
                          <HiArrowNarrowRight />
                        </Button>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
        {/* </section> */}
        <section className="bottomrow">
          <div className="container">
            <div className="row">
              <div className="col-md-4 center-text">
                <div className="copywright text-white">National University of Pakistan</div>
              </div>
              <div className="offset-md-4 col-md-4 center-text">
                <div className="copywright text-white">Copyright @ NUP 2023. All rights reserved</div>
              </div>

            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
