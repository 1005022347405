import React from "react";
import { FaHatCowboy,FaChalkboardTeacher } from "react-icons/fa";
import { VscBook } from "react-icons/vsc";
import { GiTeacher } from "react-icons/gi";
import { motion as m } from "framer-motion";
import { Helmet } from "react-helmet";
import Card from 'react-bootstrap/Card';
import { IoBookSharp } from "react-icons/io5";

const English = () => {
    return (
        <m.div
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 0.5 }}
        >
            <>
                <Helmet>
                    <title>Department of English |</title>
                </Helmet>
                {/* <Navcomponent /> */}
                <section id="courses" className="border-green">
                    <div className="course-mainheading">
                        <h1 className="font-tomatosemibold text-orange text-center"><IoBookSharp /> PROGRAM</h1>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row justify-center">
                            <div className="col-md-6">
                                <div className="course-div">
                                    <Card className="course-card">
                                        <div className="relative">
                                            <div className="courseimg-parent">
                                                <Card.Img variant="top" className="courseimg" src={'./assets/images/bsenglish.jpg'} alt="course" />
                                            </div>
                                            <div className="course-pill">
                                                <FaHatCowboy />  English
                                            </div>
                                        </div>
                                        <Card.Body className="course-cardbody">
                                            <Card.Title className="course-cardtitle">BS English (Linguistics & Literature)</Card.Title>
                                            <Card.Text className="caourse-detailpara">
                                            The combination of linguistics, literature and English language teaching adds diversity to BS English program. It enables the graduates to learn contemporary trends and innovations in language, linguistics and literary field. The program is designed keeping in view the demands of current job market. Equipped with professional skills, our graduates are well prepared for careers in fields such as media, education, civil services and business.
                                            </Card.Text>
                                            <div className="book float-right">
                                                <VscBook />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>
             
                {/* <Footer /> */}
            </>
        </m.div>
    )


}

export default English