import React from "react";
import { Helmet } from "react-helmet";
import affiliationprocedure from '../Documents/affiliationprocedure.pdf'
import { motion as m } from "framer-motion";
import Button from 'react-bootstrap/Button';

const InstructiononAffiliation = () => {
    return (
        <m.div
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Helmet>
                <title>Instruction on Affiliation |</title>
            </Helmet>
            <section id="bsdisciplinebg" className="bgimagesetting">
                <h1 className="">INSTRUCTION ON AFFILIATION</h1>
                <div id="overlay"></div>
            </section>
            <div className="container">
                <div className="row mtb">
                    <a href={affiliationprocedure} target="_blank" rel="noreferrer">
                        <Button className="Contact-btn read-btn">Click here</Button>{' '}
                    </a>
                </div>
            </div>
        </m.div>
    );
}

export default InstructiononAffiliation;